import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Form from "../forms/form/Form";
import styles from "./HomePage.module.scss";
import validationSchema from "../../utilities/validationschema";
import { clinicState } from "../../globalState";
import Loader from "../Loader/Loader";
import Background from "../Background/Background";
import MultiButtonForm from "./MultiButtonForm";
import SingleButtonForm from "./SingleButtonForm";
import { oldObgynClinicSlugMap } from "../../assets/data/old-obgyn-clinics";
import { oldPromoClinicSlugMap } from "../../assets/data/old-promo-clinics";
import { LunaJoyFoundationService } from "../../services/LunajoyFoundationService";
import { Modal } from "antd";
import formsApplicationUrl from "../../utilities/urls";
import { Image } from 'antd'
import { useTranslation } from 'react-i18next';

// Generate slug for all clinics including Old ob-gyn clinics and promo clinic
const getSlug = (clinic, typeOfScreening) => {
  let slug = oldObgynClinicSlugMap[clinic.clinicCode];
  if (slug && typeOfScreening === "obgyn") {
    return "/ob-gyn/" + slug;
  }
  slug = oldPromoClinicSlugMap[clinic.clinicCode];
  if (slug && typeOfScreening === "obgyn") {
    return "/ob-gyn/promo/" + slug;
  }
  return "/screening/" + clinic.screeningSlugs[typeOfScreening];
};

const getFormLink = (clinic, typeOfScreening) => {
  if (!clinic) {
    return `${formsApplicationUrl}/screening/${typeOfScreening}`;
  }
  const slug = getSlug(clinic, typeOfScreening);
  return formsApplicationUrl + slug;
};

const HomePage = () => {
  const { clinic } = useRecoilValue(clinicState);
  const [buttonPressed, setButtonPressed] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttons, setButtons] = useState({
    "General Assessment": { typeOfScreening: "phq9-gad7" },
  });

  // multilingual text
  const { t } = useTranslation()
  const pageTitle = t('title')
  const pageSubTitle = t('subTitle')
  const emailPlaceholder = t('emailPlaceholder')
  const footerText1 = t('footerText1')
  const footerText2 = t('footerText2')
  const takeAssessmentButton = t('takeAssessmentButton')
  const phq9gad7Button = t('phq9gad7Button')
  const epdsButton = t('epdsButton')

  const handleFormSubmit = async (values) => {
    window.rudderanalytics.page();
    setIsLoading(true);

    const clinicNameTag = clinic?.name
    ? `${clinic.name.toLowerCase()}-drop-off`
    : "general assessment-drop-off";
  
    const formData = {
      email: values?.email,
      from: "partner-landing-page",
      tags: [
        "landing page",
        clinicNameTag,
        ...(buttonPressed === "General Assessment" ? ["general assessment-drop-off"] : [])
      ],
    };

    const contact = await LunaJoyFoundationService.findOrCreateContactWithEmail(
      formData
    );
    if (!contact?.id) {
      Modal.error({
        title: "Opps! We encountered an error!",
        content: <p>Please try again after sometime!</p>,
        okText: "OK",
        centered: true,
      });
      setIsLoading(false);
      return;
    }
    const updateFormData = {
      contact_id: contact.id,
      payload: {
        customFields: [{
          id: 'OUyyT8KElN9ScCWTw71p',
          field_value: 'Drop-off Personal Information'
        }],
      },
    };
    // Update the contact with the custom field data
    const response = await LunaJoyFoundationService.updateGHLContact(updateFormData);

    if (response) {
      console.log('Custom field updated successfully');
    } else {
      console.error('Failed to update custom field');
    }

    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('email');
    currentUrl.searchParams.delete('link');

    const newUrl = getFormLink(clinic, buttons[buttonPressed].typeOfScreening);

    const finalUrl = `${newUrl}?contactId=${contact.id}&email=${encodeURIComponent(contact.email)}&link=${encodeURIComponent(currentUrl.href)}`;

    setIsLoading(false);
    window.open(finalUrl, "_self", "noopener,noreferrer");
  };


  useEffect(() => {
    if (clinic) {
      const allButton = {};
      if (!clinic.typesOfScreen) {
        window.open('https://partner.hellolunajoy.com', "_self", "noopener,noreferrer");
      } else {
        clinic.typesOfScreen.map((screening) => {
          if (screening === "Obgyn") {
            return { title: epdsButton, typeOfScreening: "obgyn" };
          } else if (screening === "PHQ9-GAD7") {
            return {
              title: phq9gad7Button,
              typeOfScreening: "phq9-gad7",
            };
          }
          return null;
        })
          .forEach((button) => {
            if (button) {
              allButton[button.title] = {
                typeOfScreening: button.typeOfScreening,
              };
            }
          });
        setButtons(allButton);
      }
    }
  }, [clinic, epdsButton, phq9gad7Button]);

  return (
    <Background clinicDetails={clinic}>
      {isLoading ? <Loader /> : null}
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <h1 className={styles.heading}>{pageTitle}</h1>
          <div className={styles.content}>
            <p className={styles.para}>
              {pageSubTitle}
            </p>
            <Form onSubmit={handleFormSubmit} validateSchema={validationSchema}>
              {(renderProps) => (
                <>
                  {!clinic || clinic?.typesOfScreen?.length === 1 || (clinic?.typesOfScreen?.includes("Geriatric") && clinic?.typesOfScreen?.length === 2) ? (
                    <SingleButtonForm
                      buttonText={takeAssessmentButton}
                      value={Object.keys(buttons)[0]}
                      onClick={setButtonPressed}
                      placeholder={emailPlaceholder}
                    />
                  ) : (
                    <MultiButtonForm
                      buttons={Object.keys(buttons)}
                      onClick={setButtonPressed}
                      placeholder={emailPlaceholder}
                    />
                  )}
                </>
              )}
            </Form>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.divider}></div>
            {clinic?.logo && <Image className={styles.dynamicLogo} src={`data:image/jpeg;base64,${btoa(String.fromCharCode(...new Uint8Array(clinic.logo?.data)))}`} alt={`${clinic.name} Logo`} preview={false} />}
            <div className={styles.bottomDescription}>
              <p>{footerText1}</p>
              <p>{footerText2}</p>
            </div>
          </div>
        </div>

      </div>
    </Background>
  );
};

export default HomePage;
