export class LunaJoyFoundationService {
  static FOUNDATION_URL = process.env.REACT_APP_FOUNDATION_LINK;

  static getClinicDetails = async () => {
    try {
      const response = await fetch(
        `${LunaJoyFoundationService.FOUNDATION_URL}/api/carePartners`
      );

      if (response.status === 200) {
        const resData = await response.json();
        console.log(resData);
        return resData;
      } else {
        throw {
          message: "Could not fetch events.",
          status: 500,
        };
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  static getClinicByCode = async (code) => {
    try {
      const response = await fetch(
        `${LunaJoyFoundationService.FOUNDATION_URL}/api/carePartners/code/${code}`
      );

      if (response.status === 200) {
        const resData = await response.json();
        return resData;
      } else {
        throw {
          message: "Could not fetch events.",
          status: 500,
        };
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  static findOrCreateContactWithEmail = async (formData) => {
    try {
      const response = await fetch(
        `${LunaJoyFoundationService.FOUNDATION_URL}/api/ghl/find-or-create-by-field`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            by: "email",
            email: formData?.email,
            from: formData?.from,
            tags: formData?.tags,
          }),
        }
      );

      if (response.status === 200) {
        const resData = await response.json();
        return resData;
      } else {
        throw {
          message: "Could not fetch events.",
          status: 500,
        };
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };

  static updateGHLContact = async (formData) => {
    try {
      const response = await fetch(
        `${LunaJoyFoundationService.FOUNDATION_URL}/api/ghl/updateContact`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 201) {
        const resData = await response.json();
        return resData;
      } else {
        throw {
          message: "Could not fetch events.",
          status: 500,
        };
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }
}
