export const DEV_PORTAL_URL = "partner-dev.hellolunajoy.com";
export const PROD_PORTAL_URL = "partner.hellolunajoy.com";
export const LOCAL_PORTAL_URL = "http://localhost:3000";
export const RUDDERSTACK_DATAPLANE_URL = 'https://hellolunajdotr.dataplane.rudderstack.com';

export const RudderStackWriteKey = () => {
    if (window.location.hostname === PROD_PORTAL_URL) {
        return "2mtCuCTjAZcDkC4gAUfmITSWGrc";
    } else if (window.location.hostname === DEV_PORTAL_URL || window.location.origin === LOCAL_PORTAL_URL) {
        return "2mtCwNgj67iNRnhNCUaKnED1GIi";
    }
};


const formsApplicationUrl = process.env.REACT_APP_FORMS_LINK

export default formsApplicationUrl