import React, { useState, useEffect } from 'react'
import Input from '../forms/input/Input'
import styles from './HomePage.module.scss'
import validationSchema from '../../utilities/validationschema'
import Button from '../forms/Button/Button'
import { useTranslation } from 'react-i18next'

const SingleButtonForm = ({ buttonText, onClick, value, placeholder }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);

  const { t } = useTranslation()
  const schema = validationSchema(t)

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    try {
      schema['email'].parse(inputValue);
      setEmailError(null);
    } catch (error) {
      setEmailError(error.errors[0]?.message);
    }
  };

  const getEmailFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('email') || '';
  };

  useEffect(() => {
    const emailFromUrl = getEmailFromUrl();
    setEmail(decodeURIComponent(emailFromUrl));
  }, []);

  const isEmailInvalid = email.trim() === '' || !!emailError;

  return (
    <>
      <div className={styles.threeSection}>
        <Input
          autofocus
          name="email"
          type="text"
          placeholder={placeholder}
          schema={schema['email']}
          onChange={handleInputChange}
          value={email}
        />
        <div className={`${styles.buttonContainer} ${isEmailInvalid ? styles.disabled : ''}`}>
          <Button
            classname="red"
            type="submit"
            onClick={isEmailInvalid ? undefined : () => onClick(value)}
            disabled={isEmailInvalid}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SingleButtonForm;
