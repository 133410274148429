import { ConfigProvider, Spin } from 'antd'
import styles from './Loader.module.scss'

const Loader = ({ loader }) => (
  <div className={styles.wrapper}>
    <div className={styles.div}>
      <ConfigProvider
        theme={{
          components: {
            Spin: {
              colorPrimary: 'var(--color-emerald-forest)'
            }
          }
        }}
      >
        <Spin size="large" />
      </ConfigProvider>
      <br />
      <div className={styles.messsage}>{loader?.message || null}</div>
    </div>
  </div>
)

export default Loader