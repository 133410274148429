import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil';
import Background from '../../components/Background/Background';
import { clinicState } from '../../globalState';
import styles from './Home.module.scss';
import Loader from '../../components/Loader/Loader';
import { LunaJoyFoundationService } from '../../services/LunajoyFoundationService';
import HomePage from '../../components/HomePage/HomePage';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { generateId } from '../../utilities/generateId';
import { RudderStackWriteKey, RUDDERSTACK_DATAPLANE_URL } from '../../utilities/urls';
import { useTranslation } from 'react-i18next';

const rudderAnalytics = new RudderAnalytics();

const rudderStackEventSourceKey = RudderStackWriteKey();
const rudderStackEventUrl = RUDDERSTACK_DATAPLANE_URL;

function initRudderStack() {
  const uniqueId = generateId();
  rudderAnalytics.load(rudderStackEventSourceKey, rudderStackEventUrl, {
    integrations: {
      All: true
    },
    logLevel: 'DEBUG',
  })
  rudderAnalytics.identify(uniqueId);
}
const Home = () => {
  const { code } = useParams()
  const [{ clinic, error }, setClinic] = useRecoilState(clinicState)
  document.title = `Lunajoy`

  // multilingual text
  const { t } = useTranslation()
  const errorTitle = t('errorTitle')

  const loadClinic = useCallback(async () => {
    try {
      const clinic = await LunaJoyFoundationService.getClinicByCode(code)
      setClinic({ clinic: { ...clinic, }, error: null })
      document.title = clinic.name
    } catch (error) {
      console.log(error)
      setClinic({ clinic: null, error: 'Not Found' })
    }
  }, [code])


  useEffect(() => {
    if (code) {
      loadClinic()
    }
  }, [code])

  // Effect for initializing RudderStack Analytics
  useEffect(() => {
    initRudderStack();

    if (typeof window !== 'undefined') {
      window.rudderanalytics = rudderAnalytics
    }
  }, []);


  if (error) return (
    <main>
      <Background clinicDetails={null}>
        <div className={styles.container}>
          <h1 className={styles.screeningHeader}>{errorTitle}</h1>
        </div>
      </Background>
    </main>
  )

  if (code && !clinic) return (
    <main>
      <Background clinicDetails={null}>
        <div className={styles.container}>
          <h1 className={styles.screeningHeader}><Loader /></h1>
        </div>
      </Background>
    </main>
  )

  return (
    <main>
      <HomePage />
    </main>
  )
}

export default Home