import { z } from 'zod'

const validationschema = (t) => {
  const emailRequiredErrorMessage = t('emailRequiredErrorMessage')
  const emailInvalidErrorMessage = t('emailInvalidErrorMessage')
  const emailSpecialCharactersErrorMessage = t('emailSpecialCharactersErrorMessage')

  return ({
    email: z.string({
      required_error: emailRequiredErrorMessage
    })
      .email({
        message: (
          <div style={{ lineHeight: '1.5' }}>
            {emailInvalidErrorMessage} <br />
            {emailSpecialCharactersErrorMessage}
          </div>
        )
      })
      .refine((value) => {
        const dotRules = /^[^.].*[^.]$/;
        const consecutiveDotsRule = !/\.\./.test(value);
        const specialCharRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);

        return dotRules.test(value) && consecutiveDotsRule && specialCharRule;
      }, {
        message: (
          <div style={{ lineHeight: '1.5' }}>
            {emailInvalidErrorMessage} <br />
            {emailSpecialCharactersErrorMessage}
          </div>
        )
      })
  })
}

export default validationschema